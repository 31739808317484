import React from 'react';
import freadLogo from '../assets/images/fread_logo.png';
import { Helmet } from 'react-helmet';

const Fread = () => {
  return (
    <div className="mt-[10%]">
      <Helmet>
        <title>Faizan Real Estate and Developers - Leading RUDA Property Specialists</title>
        <meta name="description" content="Faizan Real Estate and Developers specializes in RUDA-approved properties in Lahore. Learn about our mission, vision, and the Chahar Bagh developments." />
      </Helmet>
      <div className="container mx-auto px-2 min-h-screen">
        <div className="flex flex-col lg:flex-row justify-between items-center">
          <div className="lg:w-1/2 w-full flex flex-col justify-center leading-8 lg:order-1 order-2">
            {/* First Paragraph */}
            <div className="mb-4 lg:mb-0">
              <h1 className='text-3xl lg:text-5xl font-[700] text-WhiteLight'>
                <span className='text-RedLight'>Faizan Real Estate and Developers</span>
              </h1>
              <p className='text-lg lg:text-xl text-WhiteLight mt-6'>
                Faizan Real Estate and Developers strives to be Lahore's leading real estate partner, specializing in RUDA-approved properties. Our mission is to empower clients with the knowledge and resources necessary to achieve their property goals.
              </p>
            </div>

            
            
          </div>

          {/* Logo Section */}
          <div className="lg:w-1/2 w-full flex justify-center items-center lg:pl-8 lg:order-2 order-1 mb-4 lg:mb-0">
            <img src={freadLogo} alt="Fread Logo" className="w-full lg:w-1/2 h-auto sm:block hidden" />
          </div>
        </div>

        <div className="mt-4">
              <h2 className="text-xl lg:text-2xl font-semibold text-RedLight">Mission</h2>
              <p className='text-lg lg:text-xl text-WhiteLight mt-2'>
                This includes providing a seamless experience for RUDA property sales and acquisition, along with expert investment advisory and financing assistance. We are committed to fostering informed decision-making throughout the real estate journey, ensuring our clients secure their dream RUDA property with confidence.
              </p>

              <h2 className="text-xl lg:text-2xl font-semibold text-RedLight mt-6">Vision</h2>
              <p className='text-lg lg:text-xl text-WhiteLight mt-2'>
                Faizan Real Estate and Developers envisions a future where our clients leverage RUDA properties to build a secure and prosperous future. By providing comprehensive property management services, we go beyond the transaction to ensure clients maximize the return on their investment. Our vision is to be a trusted advisor, guiding clients through every step of the RUDA property ownership process.
              </p>

              <h2 className="text-xl lg:text-2xl font-semibold text-RedLight mt-6">Chahar Bagh</h2>
              <p className='text-lg lg:text-xl text-WhiteLight mt-2'>
                In today's world, where sustainable urban development is a global trend, Chahar Bagh emerges as a frontrunner. This meticulously planned community integrates cutting-edge concepts to redefine modern living. Imagine a vibrant tapestry woven with residential enclaves, from luxurious high-rises and modern mid-rises to trendy condos. Chahar Bagh caters to diverse lifestyles, all within a meticulously designed masterplan.
              </p>

              <h2 className="text-xl lg:text-2xl font-semibold text-RedLight mt-6">Chahar Bagh Enclave</h2>
              <p className='text-lg lg:text-xl text-WhiteLight mt-2'>
                Nestled within the visionary development of Chahar Bagh, Chahar Bagh Enclave sets a new standard for upscale living. This exclusive enclave offers a canvas for crafting your dream home in an environment that prioritizes both comfort and eco-friendliness.
              </p>
            </div>
      </div>
    </div>
  );
};

export default Fread;
