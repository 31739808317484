import React from 'react';
import Logo from '../assets/images/logo.png'
const Loader = () => {
    const handleContextMenu = (e) => {
        e.preventDefault(); // This prevents the default context menu from appearing
      }
    return (
        <div className="flex justify-center items-center h-screen">

            <img className='w-[65%] sm:w-[25%] load' src={Logo} alt="Byte Edge - Logo" onContextMenu={handleContextMenu} />
        </div>
    );
};
export default Loader;