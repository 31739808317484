import React from 'react';
import logo from '../assets/images/TIANJIN FEIZAN Logo.png';
import { Helmet } from 'react-helmet';

const TianjinFaizan = () => {
  return (
    <div className="mt-[10%]">
      <Helmet>
        <title>Tianjin Faizan Trade Co - Leading Distributor of Galvanized Coils, PPGI, and HR Coils</title>
        <meta name="description" content="Tianjin Faizan Trade Co is a premier distributor of galvanized coils, color-coated coils (PPGI), and HR coils. With a state-of-the-art distribution network in China and a robust global outreach, we are dedicated to providing high-quality products to our clients worldwide." />
      </Helmet>
      <div className="container mx-auto px-2 min-h-screen">
        <div className="flex flex-col lg:flex-row justify-between items-center">
          <div className="lg:w-1/2 w-full flex flex-col justify-center leading-8 lg:order-1 order-2">
            {/* First Paragraph */}
            <div className="mb-4 lg:mb-0">
              <h1 className='text-3xl lg:text-5xl font-[700] text-WhiteLight'>
                <span className='text-RedLight'>Tianjin Faizan Trade Co.</span>
              </h1>
              <p className='text-lg lg:text-xl text-WhiteLight mt-6'>
                Tianjin Faizan Trade Co is a premier distributor of galvanized coils, color-coated coils (PPGI), and HR coils. With a state-of-the-art distribution network in China and a robust global outreach, we are dedicated to providing high-quality products to our clients worldwide.
              </p>
            </div>
          </div>

          {/* Logo Section */}
          <div className="lg:w-1/2 w-full flex justify-center items-center lg:pl-8 lg:order-2 order-1 mb-4 lg:mb-0">
            <img src={logo} alt="Tianjin Faizan Trade Co Logo" className="w-full lg:w-1/2 h-auto sm:block hidden" />
          </div>
        </div>

        <div className="mt-4">
              <h2 className="text-xl lg:text-2xl font-semibold text-RedLight">Mission</h2>
              <p className='text-lg lg:text-xl text-WhiteLight mt-2'>
                Our mission at Tianjin Faizan Trade Co is to deliver top-quality galvanized coils, color-coated coils (PPGI), and HR coils to industries worldwide. We are committed to excellence in product quality and customer service, ensuring that our clients receive the best materials for their needs.
              </p>

              <h2 className="text-xl lg:text-2xl font-semibold text-RedLight mt-6">Vision</h2>
              <p className='text-lg lg:text-xl text-WhiteLight mt-2'>
                We envision Tianjin Faizan Trade Co as a global leader in the steel industry, expanding our reach and continually innovating to meet the evolving needs of our clients. Our vision is to build lasting partnerships based on trust, quality, and reliability.
              </p>

              <h2 className="text-xl lg:text-2xl font-semibold text-RedLight mt-6">Product Range</h2>
              <p className='text-lg lg:text-xl text-WhiteLight mt-2'>
                Our product range includes galvanized coils, color-coated coils (PPGI), and HR coils, all designed to meet the highest standards of quality and durability. Each product is carefully sourced and inspected to ensure it meets the rigorous demands of various industrial applications.
              </p>

              <h2 className="text-xl lg:text-2xl font-semibold text-RedLight mt-6">Global Outreach</h2>
              <p className='text-lg lg:text-xl text-WhiteLight mt-2'>
                With a robust global outreach, Tianjin Faizan Trade Co supplies high-quality steel products to industries around the world. Our extensive distribution network ensures that our clients receive their materials on time and in perfect condition, no matter where they are located.
              </p>
            </div>
      </div>
    </div>
  );
};

export default TianjinFaizan;
