import React, {useRef} from 'react';
import emailjs from '@emailjs/browser';
import toast, { Toaster } from 'react-hot-toast';
import { Helmet } from 'react-helmet';


const Contact = () => {
  const form = useRef();
  const sendEmail = (e) => {
    e.preventDefault();
    emailjs
      .sendForm('service_nua73w8', 'template_t2aavvk', form.current, 'nBLLo34yUUkVGdNc4')
      .then((result) => {
        console.log(result.text);
        form.current.reset();
        toast.success('Message sent successfully!');
      }, (error) => {
        console.log(error.text);
        toast.error(error.text);
      });
  };
  const handleContextMenu = (e) => {
    e.preventDefault(); // This prevents the default context menu from appearing
  }
  return (
    <div className="container mx-auto px-2 mt-[10%] min-h-screen">
      <Helmet>
        <title>Contact ARK Group - Reach Out Today</title>
        <meta name="description" content="Contact ARK Group of Companies for inquiries, partnerships, or career opportunities. Find our contact details including address, email, and phone number. We look forward to hearing from you!" />
      </Helmet>
      <div className="flex flex-col sm:flex-row justify-between items-center">
        <div className="sm:w-1/2 w-full leading-8 mb-4 sm:mb-0">
          <h1 className='sm:text-5xl text-3xl font-[700] text-WhiteLight'>
            <span className='text-RedLight'>Contact</span> Us
          </h1>
          <p className='sm:text-xl text-lg text-WhiteLight mt-6'>
            You can reach out to us through the contact form or directly via email or phone. We look forward to hearing from you!
          </p>
          <p className='sm:text-xl text-lg text-WhiteLight mt-6'>
  <span className='text-RedLight'>Address:</span> 90/B Gulberg green Service Road Batti Town lane 8 Islamabad, Pakistan<br />
  <span className='text-RedLight'>Email:</span> <a href='mailto:info@arkgroup.pk' className='text-WhiteLight'>info@arkgroup.pk</a><br />
  <span className='text-RedLight'>Phone:</span> <a href='tel:+92336999944' className='text-WhiteLight'>+92 333 699 9944</a>
</p>

        </div>
        <div className="sm:w-1/2 w-full">
          <form className="bg-gray-200 rounded-lg p-3" ref={form} onSubmit={sendEmail}>
            {/* <h2 className="text-2xl font-bold text-RedLight mb-4">Contact Form</h2> */}
            <div className="mb-3">
              <label htmlFor="name" className="block text-lg text-GrayDark mb-1">Name</label>
              <input type="text" id="name" name="from_name" placeholder="Your Name" className="w-full border border-gray-300 rounded-md py-2 px-3 focus:outline-none focus:border-GrayDark" required />
            </div>
            <div className="mb-3">
              <label htmlFor="email" className="block text-lg text-GrayDark mb-1">Email</label>
              <input type="email" id="email" name='user_email' pattern='[A-Za-z0-9._%+-]+@[A-Za-z0-9.-]+\.[A-Za-z]{1,63}$' placeholder="Your Email" className="w-full border border-gray-300 rounded-md py-2 px-3 focus:outline-none focus:border-GrayDark" required />
            </div>
            <div className="mb-6">
              <label htmlFor="message" className="block text-lg text-GrayDark mb-1">Message</label>
              <textarea id="message" name="message" rows="4" placeholder="Your Message" className="w-full border border-gray-300 rounded-md py-2 px-3 focus:outline-none focus:border-GrayDark" required></textarea>
            </div>
            <button className="bg-RedLight text-white py-2 px-4 rounded-md hover:bg-RedDark transition duration-300">Submit</button>
          </form>
        </div>
      </div>
      <Toaster
  position="top-right"
  reverseOrder={false}
/>
    </div>
  );
};

export default Contact;
